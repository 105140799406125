// @flow

import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { useIntl } from 'gatsby-plugin-intl'
import { applyStylingHoc, type StyleOuterProps, type StyleProps } from '@fcse/tsbob/dist/lib/styling'
import Layout from '../../../components/Layout/index'
import Header from '../../../modules/Header'
import Footer from '../../../modules/Footer'
import CaseStudy from '../../../components/CaseStudy'
import SEO from '../../../components/seo'
import messages from '../../../messages/pageTitles.lang'
import dimensionMessages from '../../../messages/dimensions.lang'
import metaDescription from '../../../messages/pageMetaDescriptions.lang'

import baseStyles from './bedfinder.scss'
import baseCaseMessages from '../../../components/CaseStudy/CaseStudy.lang'
import caseMessages from '../../../components/CaseStudy/messages/Bedfinder/Bedfinder.lang'

/* eslint-disable max-len */
const heroBgDesktop = (
  <StaticImage src="../../../images/casestudy/herobgdesktop.jpg" alt="" objectFit="cover" className="image" />
)
const heroImgDesktop = (
  <StaticImage src="../../../images/casestudy/bedfinder/heroimg.png" alt="" objectFit="contain" className="image" />
)
const welcomeLogo = (
  <StaticImage src="../../../images/casestudy/bedfinder/Logo.png" alt="" objectFit="contain" className="image" />
)
const welcomeImg = (
  <StaticImage src="../../../images/casestudy/bedfinder/welcome.png" alt="" objectFit="contain" className="image" />
)
const func1Img = (
  <StaticImage src="../../../images/casestudy/bedfinder/func1.png" alt="" objectFit="contain" className="image" />
)
const func2Img = (
  <StaticImage src="../../../images/casestudy/bedfinder/func2.png" alt="" objectFit="contain" className="image" />
)
const techImg = (
  <StaticImage src="../../../images/casestudy/bedfinder/tech.png" alt="" objectFit="contain" className="image" />
)
const brandingImg = (
  <StaticImage
    src="../../../images/casestudy/bedfinder/welcome.png"
    alt=""
    objectFit="contain"
    className="image image-brand"
  />
)
const colorsImg = (
  <StaticImage src="../../../images/casestudy/bedfinder/Colors.png" alt="" objectFit="contain" className="image" />
)
const recognitionImg = (
  <StaticImage src="../../../images/casestudy/bedfinder/recognition.png" alt="" objectFit="contain" className="image" />
)

const clientImg1 = (
  <StaticImage
    src="../../../images/casestudy/bedfinder/bedfinder-client-grütter.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const clientImg2 = (
  <StaticImage
    src="../../../images/casestudy/bedfinder/bedfinder-client-stirnimann.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const customerImg1 = (
  <StaticImage
    src="../../../images/casestudy/bedfinder/bedfinder-customer-01.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const customerImg2 = (
  <StaticImage
    src="../../../images/casestudy/bedfinder/bedfinder-customer-02.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
const customerImg3 = (
  <StaticImage
    src="../../../images/casestudy/bedfinder/bedfinder-customer-03.png"
    alt=""
    objectFit="contain"
    className="image"
  />
)
/* eslint-enable max-len */

import { projectsList } from '../../../components/CaseStudy/helpers'

type Props = {
  ...StyleProps
}

const data = {
  name: 'Bedfinder',
  heroTeaser: {
    bg: {
      imageDesktop: heroBgDesktop
    },
    img: heroImgDesktop,
    title: projectsList.bedfinder.title,
    description: projectsList.bedfinder.description
  },
  facts: [
    {
      title: baseCaseMessages.challenge,
      text: caseMessages.fact1Text,
      color: 'tertiary',
      icon: 'icon-eye'
    },
    {
      title: baseCaseMessages.solution,
      text: caseMessages.fact2Text,
      color: 'secondary',
      icon: 'icon-board'
    },
    {
      title: baseCaseMessages.approach,
      text: caseMessages.fact3Text,
      color: 'accent',
      icon: 'icon-devices'
    }
  ],
  welcome: {
    logo: welcomeLogo,
    text: caseMessages.welcomeText,
    img: welcomeImg
  },
  stats: [
    {
      number: 1500,
      value: caseMessages.stat1Value,
      color: 'primary',
      icon: 'icon-plane'
    },
    {
      number: 25000,
      positive: true,
      value: caseMessages.stat2Value,
      color: 'tertiary',
      icon: 'icon-user'
    },
    {
      number: 1,
      numberDimension: dimensionMessages.dimensionMillion,
      value: caseMessages.stat3Value,
      color: 'secondary',
      icon: 'icon-house'
    },
    {
      number: 3,
      numberDimension: dimensionMessages.dimensionMonths,
      value: caseMessages.stat4Value,
      color: 'accent',
      icon: 'icon-calendar-heart'
    }
  ],
  functionalitiesTitle: baseCaseMessages.mainFeaturesTitle,
  functionalities: [
    {
      title: caseMessages.functionality1Title,
      list: [caseMessages.functionality1Item1, caseMessages.functionality1Item2, caseMessages.functionality1Item3],
      img: func1Img
    },
    {
      title: caseMessages.functionality2Title,
      list: [caseMessages.functionality2Item1, caseMessages.functionality2Item2, caseMessages.functionality2Item3],
      img: func2Img,
      imgWide: true
    }
  ],
  roadmapTitle: baseCaseMessages.workflow,
  roadmap: [
    {
      title: baseCaseMessages.roadmapWorkshop,
      timeValue: '',
      timeMeasure: '',
      color: 'primary',
      icon: 'icon-flag'
    },
    {
      title: baseCaseMessages.roadmapConception,
      timeValue: '',
      timeMeasure: '',
      color: 'tertiary',
      icon: 'icon-quality'
    },
    {
      title: baseCaseMessages.roadmapDevelopment,
      timeValue: '',
      timeMeasure: '',
      color: 'secondary',
      icon: 'icon-memory'
    },
    {
      title: baseCaseMessages.roadmapRelease,
      timeLabel: '',
      timeValue: '',
      timeMeasure: '',
      color: 'accent',
      icon: 'icon-heart-filled'
    }
  ],
  brand: {
    title: baseCaseMessages.brandingDoneTitle,
    logo: {
      title: baseCaseMessages.logoTitle,
      list: [caseMessages.brandLogoItem1, caseMessages.brandLogoItem2, caseMessages.brandLogoItem3],
      img: brandingImg
    },
    colors: {
      title: baseCaseMessages.colorsTitle,
      list: [caseMessages.brandColorsItem1, caseMessages.brandColorsItem2, caseMessages.brandColorsItem3],
      img: colorsImg
    },
    recognition: {
      title: baseCaseMessages.brandRecognitionTitle,
      text: caseMessages.brandRecognitionText,
      img: recognitionImg
    },
    tech: {
      title: baseCaseMessages.techTitle,
      list: [
        baseCaseMessages.techPerfomance,
        baseCaseMessages.techScalable,
        baseCaseMessages.techFlexible,
        baseCaseMessages.techArt
      ],
      img: techImg
    },
    design: {
      title: baseCaseMessages.designTitle,
      text: baseCaseMessages.designText,
      list: [baseCaseMessages.designItemInnovative, baseCaseMessages.designItemDevices],
      img: projectsList.bedfinder.overviewImage2
    }
  },
  feedback: [
    {
      text: caseMessages.feedbackText,
      from: caseMessages.feedbackFrom,
      img: clientImg2
    },
    {
      text: caseMessages.feedbackText2,
      from: caseMessages.feedbackFrom2,
      img: clientImg1
    }
  ],
  reviews: [
    {
      img: customerImg1,
      name: 'Amanda G.',
      from: 'Trustpilot',
      title: 'Einfach zu buchen',
      rating: [1, 2, 3, 4, 5],
      text: 'Ich empfehle diese Seite sehr! Einfach zu buchen, tolle Preise. Ich benutze es auch nicht zum ersten Mal!'
    },
    {
      img: customerImg2,
      name: 'Bernd',
      from: 'Trustpilot',
      title: 'Sehr gutes Buchungssystem',
      rating: [1, 2, 3, 4, 5],
      text: 'Absolut beste Preise mit einem sehr guten Buchungssystem.'
    },
    {
      img: customerImg3,
      name: 'Karl N.',
      from: 'Trustpilot',
      title: 'Ausgezeichneter Online-Service.',
      rating: [1, 2, 3, 4, 5],
      text: 'Einfach zu navigieren und die Preise sind sehr verantwortlich.'
    }
  ]
}

const CaseBedfinder = ({ styles }: Props) => {
  const { formatMessage, locale } = useIntl()

  return (
    <Layout footer={<Footer />} header={<Header withTeaser />} styles={styles.root}>
      <SEO
        title={formatMessage(messages.TitleCaseStudyBedfinder)}
        description={formatMessage(metaDescription.CaseStudyBedfinderDescription)}
        lang={locale}
      />
      <CaseStudy data={data} styles={styles.case} />
    </Layout>
  )
}

export default applyStylingHoc(baseStyles)<$Diff<Props, StyleProps> & StyleOuterProps, Function>(CaseBedfinder)
